:root {

    // ************************************************
    // *****  default DCLT css custom properties  *****
    // ************************************************

    --body--font-family:                    'Poppins Regular', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    --body--font-weight:                    400;
    --strong--font-family:                  'Poppins Bold', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    --strong--font-weight:                  700;
    --headings--font-family:                'Poppins Bold', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    --headings--font-weight:                700;
    --headings--text-transform:             none;
    --major-headings--font-family:          'Poppins Bold', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    --major-headings--font-weight:          700;
    --major-headings--text-transform:       none;
    --major-headings--font-smoothing:       auto;

    --bg-color:             #{$body-bg};
    --color:                #{$body-color};
    --primary:              #{$dclt--primary};
    --primary--hover:       #{$dclt--primary-hover};
    --secondary:            #{$dclt--secondary};
    --secondary--hover:     #{$dclt--secondary-hover};
    --light:                #{$light};
    --dark:                 #{$dark};

    --decor--color:         var(--primary);
    --rule--color:          #{$gray-400};
    --color--emphasis:      #{$dark};
    --color--subtle:        #{$text-muted};

    --over-image-bg:        rgba(#{red($dclt--dark-blue)}, #{green($dclt--dark-blue)}, #{blue($dclt--dark-blue)}, 0.7);

    // links
    --link--color:                   #{$dclt--primary};
    --link--text-decoration:         #{$link-decoration};
    --link--color--hover:            #{$dclt--primary-hover};
    --link--text-decoration--hover:  #{$link-hover-decoration};

    // buttons (not .btn-link)
    --btn--text-transform:      uppercase;
    --btn--letter-spacing:      0.05em;
    --btn--font-size:           0.875rem;

    // header
    --header--utility-bar--bg-color:                    #{$dclt--dark-blue};
    --header--utility-bar--font-size:                   0.875rem;
    --header--utility-bar--picker--bg-color:            #{$dclt--darkest-blue};
    --header--centre-picker-toggle--bg-color:           #{darken($dclt--darkest-blue, 3%)};
    --header--centre-picker-toggle--bg-color--hover:    var(--primary--hover);
    --header--content--font-size:                       1rem;
    --header--bg-color:                                 var(--over-image-bg);
    --header--bg-color--solid:                          #2f2e4a;
    --header--logo--max-height:                         calc(var(--header--height) * 0.6);
    --header--logo--max-width:                          300px;
    --header--menu-toggle--desktop-label-transform:     translateY(1px);

    // full-menu
    --full-menu--mobile--max-width:                         400px;
    --full-menu--mobile--section-btn--font-size:            1.2rem;
    --full-menu--mobile--section-btn--icon-top:             -0.3em;
    --full-menu--desktop-feature-links--padding-top:        60px;
    --full-menu--desktop-upper--padding-top:                40px;
    --full-menu--desktop-upper--padding-bottom:             60px;
    --full-menu--desktop-upper-title--font-size:            1.6rem;
    --full-menu--desktop-lower--padding-top:                40px;
    --full-menu--desktop-lower--padding-bottom:             40px;
    --full-menu--desktop-lower--bg-color:                   #{$light};
    --full-menu--desktop-lower-title--font-size:            1.2rem;
    --full-menu--promo--bg-color:                           var(--over-image-bg);
    --full-menu--promo--color:                              white;
    --full-menu--promo--bg-color--hover:                    #{$link-hover-color};
    --full-menu--promo--color--hover:                       white;
    --full-menu--promo--h2--font-size:                      1.2rem;

    // desktop feature menu dropdowns
    --feature-menu--bg-color:                               white;
    --feature-menu--toggle-active--color:                   #{$dclt--dark-blue};

    // layout
    --main-content--top-section--bg-color:                  #{$dclt--dark-blue};

    // emergency message
    --emergency-message--bg-color:                          #{$dclt--red};
    --emergency-message--color:                             #{$dclt--light-grey};
    --emergency-message--link--color:                       white;
    --emergency-message--link--color--hover:                white;

    // hero
    --hero--mobile--panel--bg-color:                        var(--header--bg-color--solid);
    --hero--desktop--panel--bg-color:                       var(--over-image-bg);
    --hero--panel--color:                                   white;

    // signpost-bar
    --signpost-bar--bg-color:                           var(--light);
    --signpost-bar--border-width:                       0px;
    --signpost-bar--border-color:                       var(--signpost-bar--bg-color);
    --signpost-bar--icon-color:                         var(--dark);
    --signpost-bar--subtext-color:                      var(--link--color);
    --signpost-bar--icon-color--hover:                  var(--dark);
    --signpost-bar--subtext-color--hover:               var(--link--color--hover);

    // panels
    --panel--bg-color:                                  var(--light);
    --panel--color:                                     #{$dark};
    --panel--over-image--bg-color:                      var(--over-image-bg);
    --panel--over-image--color:                         white;

    --panel--dark--background-color:                    #{$dark};
    --panel--dark--color:                               var(--light);
    --panel--dark--primary:                             #{$dclt--primary};
    --panel--dark--primary--hover:                      #{$dclt--primary-hover};
    --panel--dark--secondary:                           #{$dclt--secondary};
    --panel--dark--secondary--hover:                    #{$dclt--secondary-hover};
    
    --panel--dark--decor--color:                        white;
    --panel--dark--rule--color:                         #{$gray-400};
    --panel--dark--color--emphasis:                     white;
    --panel--dark--color--subtle:                       #{rgba(white,0.6)};
    
    --panel--dark--link--color:                   white;
    --panel--dark--link--text-decoration:         underline;
    --panel--dark--link--color--hover:            white;

    // testimonials
    --testimonials-section--bg-color:                   var(--light);
    --testimonial--color:                               var(--color--subtle);
    --testimonial--stars--color:                        var(--primary);
    --testimonial--quote--color:                        var(--dark);

    // timetable
    --timetable--date-input--background-image:          url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath style='fill:%23c91f28' d='M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM64 304C64 312.8 71.16 320 80 320H112C120.8 320 128 312.8 128 304V272C128 263.2 120.8 256 112 256H80C71.16 256 64 263.2 64 272V304zM192 304C192 312.8 199.2 320 208 320H240C248.8 320 256 312.8 256 304V272C256 263.2 248.8 256 240 256H208C199.2 256 192 263.2 192 272V304zM336 256C327.2 256 320 263.2 320 272V304C320 312.8 327.2 320 336 320H368C376.8 320 384 312.8 384 304V272C384 263.2 376.8 256 368 256H336zM64 432C64 440.8 71.16 448 80 448H112C120.8 448 128 440.8 128 432V400C128 391.2 120.8 384 112 384H80C71.16 384 64 391.2 64 400V432zM208 384C199.2 384 192 391.2 192 400V432C192 440.8 199.2 448 208 448H240C248.8 448 256 440.8 256 432V400C256 391.2 248.8 384 240 384H208zM320 432C320 440.8 327.2 448 336 448H368C376.8 448 384 440.8 384 432V400C384 391.2 376.8 384 368 384H336C327.2 384 320 391.2 320 400V432z'/%3E%3C/svg%3E");
    --timetable--column-headings--color:                var(--color--subtle);
    --timetable--info--color:                           rgb(21, 170, 46);
    --timetable--warning--color:                        #{$dclt--red};
    --timetable--alternate-darker-shade:                #fafafa;

    // footer
    --footer--bg-color:                                 #{$dclt--dark-blue};
    --footer--color:                                    #{darken($dclt--light-grey, 8%)};
    --footer--link--color:                              var(--footer--color);
    --footer--link--color--hover:                       white;
    --footer--headings--color:                          white;
    --footer--headings--color--hover:                   white;
    --footer--divider--bg-color:                        #{rgba($dclt--light-grey, 0.8)};


    // *****************************************************
    // *****  Crookhill css custom property overrides  *****
    // *****************************************************

    body.site-layout.crookhill {

        --primary:              #{$crookhill--primary};
        --primary--hover:       #{$crookhill--primary-hover};
        --secondary:            #{$crookhill--secondary};
        --secondary--hover:     #{$crookhill--secondary-hover};

        --light:                #{$crookhill--sand};

        --over-image-bg:                                    rgba(11,77,0,0.7);

        // links
        --link--color:                                      var(--primary);
        --link--color--hover:                               var(--primary--hover);

        // header
        --header--utility-bar--bg-color:                    rgb(40,40,40);
        --header--centre-picker-toggle--bg-color:           black;
        --header--centre-picker-toggle--bg-color--hover:    var(--primary--hover);
        --header--bg-color:                                 var(--over-image-bg);
        --header--bg-color--solid:                          var(--secondary);

        // desktop feature menu dropdowns
        --feature-menu--toggle-active--color:                   var(--header--bg-color--solid);
    
        // layout
        --main-content--top-section--bg-color:                  var(--header--bg-color--solid);

        // hero
        --hero--mobile--panel--bg-color:                        var(--header--bg-color--solid);
        --hero--desktop--panel--bg-color:                       var(--over-image-bg);

        // panels
        --panel--over-image--bg-color:                      var(--over-image-bg);

        --panel--dark--background-color:                    #{$crookhill--dark-green};
        --panel--dark--primary:                             var(--primary);
        --panel--dark--primary--hover:                      var(--primary--hover);

        // timetable
        --timetable--column-headings--color:                #{$crookhill--light-green};
        --timetable--alternate-darker-shade:                #f8faed;

        // footer
        --footer--bg-color:                                 #{$crookhill--dark-green};

    }


    // ****************************************************
    // *****  Hatfield css custom property overrides  *****
    // ****************************************************

    body.site-layout.hatfield {

        --major-headings--font-family:              'Phosphate Inline', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
        --major-headings--text-transform:           uppercase;
        --major-headings--font-smoothing:           antialiased;

        --primary:              #{$hatfield--primary};
        --primary--hover:       #{$hatfield--primary-hover};
        --secondary:            #{$hatfield--secondary};
        --secondary--hover:     #{$hatfield--secondary-hover};

        --light:                #{$hatfield--light-yellow};
        --dark:                 #{$hatfield--dark-grey};

        --over-image-bg:                                    rgba(#{red($hatfield--green)}, #{green($hatfield--green)}, #{blue($hatfield--green)}, 0.7);

        // links
        --link--color:                                      var(--primary);
        --link--color--hover:                               var(--primary--hover);

        // header
        --header--utility-bar--bg-color:                    rgb(40,40,40);
        --header--centre-picker-toggle--bg-color:           black;
        --header--centre-picker-toggle--bg-color--hover:    var(--primary--hover);
        --header--bg-color:                                 #{$hatfield--green};
        --header--bg-color--solid:                          #{$hatfield--green};

        // desktop feature menu dropdowns
        --feature-menu--toggle-active--color:                   var(--header--bg-color--solid);
    
        // layout
        --main-content--top-section--bg-color:                  #{$hatfield--dark-grey};

        // hero
        --hero--mobile--panel--bg-color:                        var(--header--bg-color--solid);
        --hero--desktop--panel--bg-color:                       var(--over-image-bg);

        // signpost-bar
        --signpost-bar--bg-color:                           #{$hatfield--primary};
        --signpost-bar--border-width:                       2px;
        --signpost-bar--border-color:                       white;
        --signpost-bar--icon-color:                         white;
        --signpost-bar--subtext-color:                      #{$hatfield--light-yellow};
        --signpost-bar--icon-color--hover:                  white;
        --signpost-bar--subtext-color--hover:               white;

        // panels
        --panel--over-image--bg-color:                      var(--over-image-bg);

        --panel--dark--background-color:                    #{$hatfield--green};
        --panel--dark--primary:                             var(--primary);
        --panel--dark--primary--hover:                      var(--primary--hover);

        // timetable
        --timetable--column-headings--color:                #{$hatfield--orange};
        --timetable--alternate-darker-shade:                #fffbf2;

        // footer
        --footer--bg-color:                                 black;
        
    }


    // ******************************************************************************
    // *****  Choose Fitness (and derivitives) - css custom property overrides  *****
    // ******************************************************************************

    body.site-layout.choose-fitness, 
    body.site-layout.choose-swim, 
    body.site-layout.choose-skate,
    body.site-layout.choose-gymnastics, 
    body.site-layout.choose-sport, 
    body.site-layout.choose-cycle {

        --primary:              #{$choose-fitness--primary};
        --primary--hover:       #{$choose-fitness--primary-hover};

        --over-image-bg:                                    #{rgba(black, .6)};

        // links
        --link--color:                   var(--primary);
        --link--color--hover:            var(--primary--hover);

        // header
        --header--utility-bar--bg-color:                    rgb(40,40,40);
        --header--centre-picker-toggle--bg-color:           black;
        --header--centre-picker-toggle--bg-color--hover:    var(--primary--hover);
        --header--bg-color:                                 black;
        --header--bg-color--solid:                          black;

        // hero
        --hero--mobile--panel--bg-color:                        var(--header--bg-color--solid);
        --hero--desktop--panel--bg-color:                       var(--over-image-bg);

        // signpost-bar
        --signpost-bar--subtext-color:                      var(--link--color);
        --signpost-bar--subtext-color--hover:               var(--link--color--hover);

        // panels
        --panel--over-image--bg-color:                      var(--over-image-bg);
        --panel--dark--background-color:                    black;
        --panel--dark--primary:                             var(--primary);
        --panel--dark--primary--hover:                      var(--primary--hover);
        
        // layout
        --main-content--top-section--bg-color:                  black;

        // footer
        --footer--bg-color:                                 black;

    }

    body.site-layout.choose-swim {
        --primary:              #{$choose-swim--primary};
        --primary--hover:       #{$choose-swim--primary-hover};
    }

    body.site-layout.choose-skate {
        --primary:              #{$choose-skate--primary};
        --primary--hover:       #{$choose-skate--primary-hover};
    }

    body.site-layout.choose-gymnastics {
        --primary:              #{$choose-gymnastics--primary};
        --primary--hover:       #{$choose-gymnastics--primary-hover};
    }

    body.site-layout.choose-sport {
        --primary:              #{$choose-sport--primary};
        --primary--hover:       #{$choose-sport--primary-hover};
    }

    body.site-layout.choose-cycle {
        --primary:              #{$choose-cycle--primary};
        --primary--hover:       #{$choose-cycle--primary-hover};
    }


    // ******************************************************
    // *****  Ice Caps - css custom property overrides  *****
    // ******************************************************

    body.site-layout.ice-caps {

        --primary:              #{$ice-caps--primary};
        --primary--hover:       #{$ice-caps--primary-hover};

        --over-image-bg:                                    #{rgba($ice-caps--black, .6)};

        // links
        --link--color:                   var(--primary);
        --link--color--hover:            var(--primary--hover);

        // header
        --header--utility-bar--bg-color:                    black;
        --header--centre-picker-toggle--bg-color:           #{$ice-caps--black};
        --header--centre-picker-toggle--bg-color--hover:    var(--primary--hover);
        --header--bg-color:                                 #{$ice-caps--black};
        --header--bg-color--solid:                          #{$ice-caps--black};

        // hero
        --hero--mobile--panel--bg-color:                        var(--header--bg-color--solid);
        --hero--desktop--panel--bg-color:                       var(--over-image-bg);

        // signpost-bar
        --signpost-bar--subtext-color:                      var(--link--color);
        --signpost-bar--subtext-color--hover:               var(--link--color--hover);

        // panels
        --panel--over-image--bg-color:                      var(--over-image-bg);
        --panel--dark--background-color:                    #{$ice-caps--black};
        --panel--dark--primary:                             var(--primary);
        --panel--dark--primary--hover:                      var(--primary--hover);
        
        // layout
        --main-content--top-section--bg-color:                  #{$ice-caps--black};

        // footer
        --footer--bg-color:                                 #{$ice-caps--black};

    }


    // ******************************************
    // *****  In-page (block-level) themes  *****
    // ******************************************

    // summer

    .theme--summer-yellow {
        --bg-color:             #{$color--summer-yellow};
        background-color:       $color--summer-yellow !important;
        backdrop-filter:        none !important;
        --color:                #{$body-color};
        --primary:              #{$dclt--primary};
        --primary--hover:       #{$dclt--primary-hover};
        --secondary:            #{$dclt--secondary};
        --secondary--hover:     #{$dclt--secondary-hover};
        --light:                #{$light};
        --dark:                 #{$dark};
        --decor--color:         var(--primary);
        --rule--color:          #{$gray-400};
        --color--emphasis:      #{$dark};
        --color--subtle:        #{$text-muted};
        --over-image-bg:        rgba(#{red($dclt--dark-blue)}, #{green($dclt--dark-blue)}, #{blue($dclt--dark-blue)}, 0.7);
        // links
        --link--color:                   #{$dclt--primary};
        --link--text-decoration:         #{$link-decoration};
        --link--color--hover:            #{$dclt--primary-hover};
        --link--text-decoration--hover:  #{$link-hover-decoration};
    }

    .theme--summer-blue {
        --bg-color:             #{$color--summer-blue};
        background-color:       $color--summer-blue !important;
        backdrop-filter:        none !important;
        --color:                #{$body-color};
        --primary:              #{$dclt--primary};
        --primary--hover:       #{$dclt--primary-hover};
        --secondary:            #{$dclt--secondary};
        --secondary--hover:     #{$dclt--secondary-hover};
        --light:                #{$light};
        --dark:                 #{$dark};
        --decor--color:         var(--primary);
        --rule--color:          #{$gray-400};
        --color--emphasis:      #{$dark};
        --color--subtle:        #{$text-muted};
        --over-image-bg:        rgba(#{red($dclt--dark-blue)}, #{green($dclt--dark-blue)}, #{blue($dclt--dark-blue)}, 0.7);
        // links
        --link--color:                   #{$dclt--primary};
        --link--text-decoration:         #{$link-decoration};
        --link--color--hover:            #{$dclt--primary-hover};
        --link--text-decoration--hover:  #{$link-hover-decoration};
    }

    .theme--summer-orange {
        --bg-color:             #{$color--summer-orange};
        background-color:       $color--summer-orange !important;
        backdrop-filter:        none !important;
        --color:                #{$body-color};
        --primary:              #{$dclt--primary};
        --primary--hover:       #{$dclt--primary-hover};
        --secondary:            #{$dclt--secondary};
        --secondary--hover:     #{$dclt--secondary-hover};
        --light:                #{$light};
        --dark:                 #{$dark};
        --decor--color:         var(--primary);
        --rule--color:          #{$gray-400};
        --color--emphasis:      #{$dark};
        --color--subtle:        #{$text-muted};
        --over-image-bg:        rgba(#{red($dclt--dark-blue)}, #{green($dclt--dark-blue)}, #{blue($dclt--dark-blue)}, 0.7);
        // links
        --link--color:                   #{$dclt--primary};
        --link--text-decoration:         #{$link-decoration};
        --link--color--hover:            #{$dclt--primary-hover};
        --link--text-decoration--hover:  #{$link-hover-decoration};
    }

    .theme--summer-lilac {
        --bg-color:             #{$color--summer-lilac};
        background-color:       $color--summer-lilac !important;
        backdrop-filter:        none !important;
        --color:                #{$body-color};
        --primary:              #{$dclt--primary};
        --primary--hover:       #{$dclt--primary-hover};
        --secondary:            #{$dclt--secondary};
        --secondary--hover:     #{$dclt--secondary-hover};
        --light:                #{$light};
        --dark:                 #{$dark};
        --decor--color:         var(--primary);
        --rule--color:          #{$gray-400};
        --color--emphasis:      #{$dark};
        --color--subtle:        #{$text-muted};
        --over-image-bg:        rgba(#{red($dclt--dark-blue)}, #{green($dclt--dark-blue)}, #{blue($dclt--dark-blue)}, 0.7);
        // links
        --link--color:                   #{$dclt--primary};
        --link--text-decoration:         #{$link-decoration};
        --link--color--hover:            #{$dclt--primary-hover};
        --link--text-decoration--hover:  #{$link-hover-decoration};
    }

    // halloween

    .theme--halloween-yellow {
        --bg-color:             #{$color--halloween-yellow};
        background-color:       $color--halloween-yellow !important;
        backdrop-filter:        none !important;
        --color:                #{$body-color};
        --primary:              #{$dclt--primary};
        --primary--hover:       #{$dclt--primary-hover};
        --secondary:            #{$dclt--secondary};
        --secondary--hover:     #{$dclt--secondary-hover};
        --light:                #{$light};
        --dark:                 #{$dark};
        --decor--color:         var(--primary);
        --rule--color:          #{$gray-400};
        --color--emphasis:      #{$dark};
        --color--subtle:        #{$text-muted};
        --over-image-bg:        rgba(#{red($dclt--dark-blue)}, #{green($dclt--dark-blue)}, #{blue($dclt--dark-blue)}, 0.7);
        // links
        --link--color:                   #{$dclt--primary};
        --link--text-decoration:         #{$link-decoration};
        --link--color--hover:            #{$dclt--primary-hover};
        --link--text-decoration--hover:  #{$link-hover-decoration};
    }

    .theme--halloween-orange {
        --bg-color:             #{$color--halloween-orange};
        background-color:       $color--halloween-orange !important;
        backdrop-filter:        none !important;
        --color:                #{$body-color};
        --primary:              #{$dclt--primary};
        --primary--hover:       #{$dclt--primary-hover};
        --secondary:            #{$dclt--secondary};
        --secondary--hover:     #{$dclt--secondary-hover};
        --light:                #{$light};
        --dark:                 #{$dark};
        --decor--color:         var(--primary);
        --rule--color:          #{$gray-400};
        --color--emphasis:      white;
        --color--subtle:        #{$text-muted};
        --over-image-bg:        rgba(#{red($dclt--dark-blue)}, #{green($dclt--dark-blue)}, #{blue($dclt--dark-blue)}, 0.7);
        // links
        --link--color:                   #{$dclt--primary};
        --link--text-decoration:         #{$link-decoration};
        --link--color--hover:            #{$dclt--primary-hover};
        --link--text-decoration--hover:  #{$link-hover-decoration};
    }

    .theme--halloween-light-blue {
        --bg-color:             #{$color--halloween-light-blue};
        background-color:       $color--halloween-light-blue !important;
        backdrop-filter:        none !important;
        --color:                #{$body-color};
        --primary:              #{$dclt--primary};
        --primary--hover:       #{$dclt--primary-hover};
        --secondary:            #{$dclt--secondary};
        --secondary--hover:     #{$dclt--secondary-hover};
        --light:                #{$light};
        --dark:                 #{$dark};
        --decor--color:         var(--primary);
        --rule--color:          #{$gray-400};
        --color--emphasis:      #{$dark};
        --color--subtle:        #{$text-muted};
        --over-image-bg:        rgba(#{red($dclt--dark-blue)}, #{green($dclt--dark-blue)}, #{blue($dclt--dark-blue)}, 0.7);
        // links
        --link--color:                   #{$dclt--primary};
        --link--text-decoration:         #{$link-decoration};
        --link--color--hover:            #{$dclt--primary-hover};
        --link--text-decoration--hover:  #{$link-hover-decoration};
    }

    .theme--halloween-dark-blue {
        --bg-color:             #{$color--halloween-dark-blue};
        background-color:       $color--halloween-dark-blue !important;
        backdrop-filter:        none !important;
        --light:                #{$light};
        --dark:                 #{$dark};
        --color:                var(--light);
        color:                  var(--color);
        --primary:              #{$dclt--primary};
        --primary--hover:       #{$dclt--primary-hover};
        --secondary:            #{$dclt--secondary};
        --secondary--hover:     #{$dclt--secondary-hover};
        --decor--color:         white;
        --rule--color:          #{$gray-400};
        --color--emphasis:      white;
        --color--subtle:        #{rgba(white,0.6)};
        --panel--bg-color:      var(--bg-color);
        --panel--color:         var(--color);
        // links
        --link--color:                   white;
        --link--text-decoration:         underline;
        --link--color--hover:            white;
        --link--text-decoration--hover:  #{$link-hover-decoration};
    }

    .theme--halloween-black {
        --bg-color:             #{$color--halloween-black};
        background-color:       $color--halloween-black !important;
        backdrop-filter:        none !important;
        --light:                #{$light};
        --dark:                 #{$dark};
        --color:                var(--light);
        color:                  var(--color);
        --primary:              #{$dclt--primary};
        --primary--hover:       #{$dclt--primary-hover};
        --secondary:            #{$dclt--secondary};
        --secondary--hover:     #{$dclt--secondary-hover};
        --decor--color:         white;
        --rule--color:          #{$gray-400};
        --color--emphasis:      white;
        --color--subtle:        #{rgba(white,0.6)};
        --panel--bg-color:      var(--bg-color);
        --panel--color:         var(--color);
        // links
        --link--color:                   white;
        --link--text-decoration:         underline;
        --link--color--hover:            white;
        --link--text-decoration--hover:  #{$link-hover-decoration};
    }

    // Christmas

    .theme--christmas-brown {
        --bg-color:             #{$color--christmas-brown};
        background-color:       $color--christmas-brown !important;
        backdrop-filter:        none !important;
        --light:                #{$light};
        --dark:                 #{$dark};
        --color:                var(--light);
        color:                  var(--color);
        --primary:              #{$dclt--primary};
        --primary--hover:       #{$dclt--primary-hover};
        --secondary:            #{$dclt--secondary};
        --secondary--hover:     #{$dclt--secondary-hover};
        --decor--color:         white;
        --rule--color:          #{$gray-400};
        --color--emphasis:      white;
        --color--subtle:        #{rgba(white,0.7)};
        --panel--bg-color:      var(--bg-color);
        --panel--color:         var(--color);
        // links
        --link--color:                   white;
        --link--text-decoration:         underline;
        --link--color--hover:            white;
        --link--text-decoration--hover:  #{$link-hover-decoration};
        // buttons
        --primary:              #{$color--christmas-dark-red};
        --primary--hover:       #{darken($color--christmas-dark-red, 10%)};
    }

    .theme--christmas-cream {
        --bg-color:             #{$color--christmas-cream};
        background-color:       $color--christmas-cream !important;
    }

    .theme--christmas-green {
        --bg-color:             #{$color--christmas-green};
        background-color:       $color--christmas-green !important;
        backdrop-filter:        none !important;
        --light:                #{$light};
        --dark:                 #{$dark};
        --color:                var(--light);
        color:                  var(--color);
        --primary:              #{$dclt--primary};
        --primary--hover:       #{$dclt--primary-hover};
        --secondary:            #{$dclt--secondary};
        --secondary--hover:     #{$dclt--secondary-hover};
        --decor--color:         white;
        --rule--color:          #{$gray-400};
        --color--emphasis:      white;
        --color--subtle:        #{rgba(white,0.7)};
        --panel--bg-color:      var(--bg-color);
        --panel--color:         var(--color);
        // links
        --link--color:                   white;
        --link--text-decoration:         underline;
        --link--color--hover:            white;
        --link--text-decoration--hover:  #{$link-hover-decoration};
    }

    .theme--christmas-red {
        --bg-color:             #{$color--christmas-red};
        background-color:       $color--christmas-red !important;
        backdrop-filter:        none !important;
        --light:                #{$light};
        --dark:                 #{$dark};
        --color:                var(--light);
        color:                  var(--color);
        --primary:              #{$dclt--primary};
        --primary--hover:       #{$dclt--primary-hover};
        --secondary:            #{$dclt--secondary};
        --secondary--hover:     #{$dclt--secondary-hover};
        --decor--color:         white;
        --rule--color:          #{$gray-400};
        --color--emphasis:      white;
        --color--subtle:        #{rgba(white,0.7)};
        --panel--bg-color:      var(--bg-color);
        --panel--color:         var(--color);
        // links
        --link--color:                   white;
        --link--text-decoration:         underline;
        --link--color--hover:            white;
        --link--text-decoration--hover:  #{$link-hover-decoration};
        // buttons
        --primary:              #{$color--christmas-dark-red};
        --primary--hover:       #{darken($color--christmas-dark-red, 10%)};
    }

    .theme--christmas-dark-red {
        --bg-color:             #{$color--christmas-dark-red};
        background-color:       $color--christmas-dark-red !important;
        backdrop-filter:        none !important;
        --light:                #{$light};
        --dark:                 #{$dark};
        --color:                var(--light);
        color:                  var(--color);
        --primary:              #{$dclt--primary};
        --primary--hover:       #{$dclt--primary-hover};
        --secondary:            #{$dclt--secondary};
        --secondary--hover:     #{$dclt--secondary-hover};
        --decor--color:         white;
        --rule--color:          #{$gray-400};
        --color--emphasis:      white;
        --color--subtle:        #{rgba(white,0.7)};
        --panel--bg-color:      var(--bg-color);
        --panel--color:         var(--color);
        // links
        --link--color:                   white;
        --link--text-decoration:         underline;
        --link--color--hover:            white;
        --link--text-decoration--hover:  #{$link-hover-decoration};
    }

}
