.centre-picker {
    .dropdown-toggle {

        svg {
            width: 20px;
            height: 20px;
            vertical-align: middle;
            margin-right: 5px;
        }
    }

    .dropdown-menu {
        padding: 0;
        min-width: 320px;
        max-height: calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--bs-gutter-x)));
        overflow-y: auto;

        .dropdown-item {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 0.65rem 1rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            white-space: normal;
            line-height: 1.2;

            .content {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                gap: 2px;
                width: 100%;

                .details p {
                    display: inline;
                    margin: 0;

                    &:not(:last-child):after {
                        content: '';
                        margin: 0 0.5em;
                        border-right: 1px solid currentColor;
                        opacity: 0.4;
                    }
                }
            }

            .indicator {
                flex: 0 0 auto;
            }

            &.active {
                background-color: var(--primary);

                .text-muted {
                    color: white !important;
                }
            }
        }

        li:last-child .dropdown-item {
            border-bottom: none;
        }
    }
}

.signpost-bar {
    background-color: var(--signpost-bar--bg-color);
    border-width: var(--signpost-bar--border-width);
    border-style: solid;
    border-color: var(--signpost-bar--border-color);
    ul {
        --gap: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: var(--gap);
        li {
            width: calc((100% - var(--gap)) / 2); // 2 col
            @include media-breakpoint-up(sm) {
                width: calc((100% - (2 * var(--gap))) / 3); // 3 col
            }
            @include media-breakpoint-up(md) {
                width: calc((100% - (3 * var(--gap))) / 4); // 4 col
            }
            @include media-breakpoint-up(lg) {
                width: calc((100% - (4 * var(--gap))) / 5); // 5 col
            }
            @include media-breakpoint-up(xl) {
                width: calc((100% - (5 * var(--gap))) / 6); // 6 col
            }
            a {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: max(14px, 0.875rem);
                color: var(--signpost-bar--icon-color);
                & > svg {
                    font-size: 3rem;
                    margin-bottom: 3px;
                    transform: scale(.86);
                    transition: transform 500ms ease-in-out;
                    will-change: transform;
                }
                strong {
                    font-family: var(--body--font-family);
                    font-weight: var(--body--font-weight);
                }
                span {
                    color: var(--signpost-bar--subtext-color);
                }
                &:hover, &:focus {
                    color: var(--signpost-bar--icon-color--hover);
                    text-decoration: none;
                    & > svg {
                        transform: scale(1);
                        transition: transform 250ms ease-in-out;
                    }
                    span {
                        color: var(--signpost-bar--subtext-color--hover);
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.logos-swiper {
    position: relative;
    padding: 0 40px;
    will-change: transform;
    user-select: none;
    .swiper-wrapper {
        align-items: center;
        z-index: 1;
        figure img {
            display: block;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100px;
            object-fit: contain;
            font-family: 'object-fit: contain';
        }
    }
    .logos-swiper--prev, .logos-swiper--next {
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.6rem;
        padding: 0;
        color: var(--link--color);
        &:not(:disabled):not(.disabled) {
            &:hover, &:focus {
                color: var(--link--color--hover);
            }
        }
    }
    .logos-swiper--prev {
        left: 0;
    }
    .logos-swiper--next {
        right: 0;
    }
}

// taglist

ul.taglist {
    display: inline-flex;
    gap: 5px;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        a {
            display: inline-flex;
            align-items: center;
            border: 1px solid var(--color--subtle);
            background-color: transparent;
            color: var(--color--subtle);
            padding: 2px 8px;
            min-height: 16px;
            border-radius: 3rem;
            font-family: var(--strong--font-family);
            font-weight: var(--strong--font-weight);
            font-size: 10px;
            text-transform: uppercase;
            &:hover, :focus {
                border-color: var(--link--color--hover);
                background-color: var(--link--color--hover);
                color: var(--bg-color);
                text-decoration: none;
            }
        }
    }
}


// visual listing

.visual-listing {
    &--item {
        display: block;
        background-color: white;
        box-shadow: 0 2px 6px rgba(black, 0.3);
        margin-bottom: $grid-gutter-width;
        &--image {
            img {
                aspect-ratio: 16/9;
                object-fit: cover;
            }
            &.square {
                img {
                    aspect-ratio: 1;
                }
            }
            .visual-listing--item--label {
                display: inline-block;
                position: absolute;
                z-index: 2;
                top: 0;
                right: 0;
                font-size: max(12px, 0.6rem);
                background-color: var(--primary);
                color: white;
                text-transform: uppercase;
                padding: 3px 5px;
            }
        }
        &--content {
            & > *:last-child, & > *[data-hm-section]:last-child > *:last-child {
                margin-bottom: 0;
            }
        }
    }
    a.visual-listing--item {
        color: var(--color);
        .visual-listing--item--content {
            h3 {
                color: var(--color);
            }
            p.text-muted {
                color: $text-muted;
            }
        }
        &:hover, &:focus {
            text-decoration: none;
            color: var(--color);
            .visual-listing--item--content {
                h3 {
                    text-decoration: underline;
                    color: var(--link-color--hover);
                }
                p.text-muted {
                    color: $text-muted;
                }
            }
        }
    }
}

// listing-filters

.listing-filters {
    background-color: white;
    button[data-bs-toggle="collapse"] {
        display: flex;
        gap: 5px;
        width: 100%;
        align-items: center;
        .indicator {
            margin-left: auto;
        }
    }
    form {
        display: flex;
        flex-direction: column;
        gap: 30px;
        @include media-breakpoint-up(lg) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-end;
            .listing-filters--column:last-child {
                margin-left: auto;
            }
        }
    }
}

// pagination
.pagination {
    .page-item {
        &:not(:last-child) {
            margin-right: 1px;
        }
        .page-link {
            color: var(--link--color);
            background-color: white;
            &:hover, &:focus {
                color: white;
                background-color: var(--link--color--hover);
            }
        }
        &.active {
            .page-link {
                color: white;
                background-color: var(--primary);
                &:hover, &:focus {
                    color: white;
                    background-color: var(--primary);
                }
            }
        }
    }
}

.signpost-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
    width: 100%;
    li {
        display: block;
        margin: 0;
        flex: 1 0 auto;
        align-items: stretch;
        width: 100%;
        @include media-breakpoint-up(md) {
            width: calc((100% - 1px) / 2);
        }
        @include media-breakpoint-up(lg) {
            width: calc((100% - 2px) / 3);
        }
        a {
            display: flex;
            width: 100%;
            min-height: 100%;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 2rem; // default-padding
            gap: .5rem;
            text-align: left;
            text-decoration: none;
            background-color: var(--primary);
            color: white;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
            .signpost-list--title {
                display: block;
                width: 100%;
                position: relative;
                padding-right: 3rem;
                font-size: $h3-font-size;
                line-height: 1.2;
                font-family: var(--major-headings--font-family);
                font-weight: var(--major-headings--font-weight);
                text-transform: var(--major-headings--text-transform);
                @include non-retina-font-smoothing-antialiased;
                i, svg {
                    position: absolute;
                    top: .1em;
                    right: 0;
                }
            }
            .signpost-list--description {
                display: block;
                width: 100%;
                opacity: .8;
            }
            &:hover, &:focus {
                background-color: var(--primary--hover);
                color: white;
                text-decoration: none;
                .signpost-list--title {
                    text-decoration: underline;
                }
            }
        }
    }
    @supports (container-type: inline-size) {
        container-type: inline-size;
        li {
            width: 100%;
        }
        @container (min-width: 600px) {
            li {
                width: calc((100% - 1px) / 2);
            }
        }
        @container (min-width: 800px) {
            li {
                width: calc((100% - 2px) / 3);
            }
            &:has(li:nth-child(4)):not(:has(li:nth-child(5))) { // handle when there are 4 items
                li {
                    width: calc((100% - 1px) / 2);
                }
            }
        }
    }
}
