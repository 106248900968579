:root {

    --vpHeight: 100vh;
    --vpWidth: 100vw;
    

    // ******************************
    // *****  layout variables  *****
    // ******************************


    --top-utility-bar--height: 45px;
    --header--height: 80px;

    @include media-breakpoint-up(sm) {
        --bs-gutter-x: 30px;
    }

    @include media-breakpoint-up(lg) {
        --top-utility-bar--height: 50px;
        --header--height: 120px;
    }

}

// body

body.site-layout {
    display: flex;
    flex-direction: column;
    min-height: var(--vpHeight);
    padding-top: calc(var(--top-utility-bar--height) + var(--header--height));
    background-color: var(--bg-color);
    color: var(--color);

    #main-content {
        flex: 1 1 auto;
        .main-content--top-section {
            background-color: var(--main-content--top-section--bg-color);
        }
    }

    #main-footer {
        flex: 0 0 auto;
    }
}

// Link colours

a:not(.btn, .dropdown-item, .page-link, .visual-listing--item), .btn.btn-link {
    color: var(--link--color);
    text-decoration: var(--link--text-decoration);
    &:hover, &:focus {
        color: var(--link--color--hover);
        text-decoration: var(--link--text-decoration--hover);
    }
}

// height-match margin fix
*[data-hm-section] {
    padding: 1px 0;
    margin: -1px 0;
}

// overlapping-intro-section

.overlapping-intro-section {
    position: relative;
    z-index: 2;
    @include media-breakpoint-up(lg) {
        margin-top: calc(-1 * var(--header--height));
    }
}

// emergency message

.emergency-message {
    background-color: var(--emergency-message--bg-color);
    color: var(--emergency-message--color);
    --link--color: var(--emergency-message--link--color);
    --link--color--hover: var(--emergency-message--link--color--hover);
    position: fixed;
    top: calc(var(--top-utility-bar--height) + var(--header--height));
    left: 0;
    width: 100%;
    z-index: 9;
    border: 0;
    max-height: calc(90vh - (var(--top-utility-bar--height) + var(--header--height)));
    overflow-y: auto;
    transition: transform 300ms ease-in-out;
    will-change: transform;
    .header-hidden:not(.mobile-menu-active) & {
        transform: translate3d(0,calc(-1 * (var(--top-utility-bar--height) + var(--header--height))),0);
    }
    a {
        font-family: var(--strong--font-family);
        font-weight: var(--strong--font-weight);
    }
    .close {
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

// page-title-area

.page-title-area {
    margin-top: 40px;
    margin-bottom: 40px;
    .page-title-area--inner {
        .breadcrumb {
            background-color: transparent;
            font-size: 1rem;
            justify-content: center;
            margin-bottom: 0.5rem;
            .breadcrumb-item {
                text-align: center;
                &:not(:first-child):before {
                    content: '';
                    display: inline-block;
                    vertical-align: baseline;
                    height: 1.4em;
                    width: 1em;
                    background: no-repeat left center url("data:image/svg+xml,%3Csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512'%3E%3Cpath fill='%23c1c6c8' d='M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z' %3E%3C/path%3E%3C/svg%3E");
                    transform: scale(0.8);
                }
                a {
                    &:hover, &:focus {
                        text-decoration: underline;
                    }
                }
            }
        }
        h1 {
            text-align: center;
        }
    }

    // hero overlap
    @include media-breakpoint-up(md) {
        .hero + & {
            position: relative;
            z-index: 2;
            min-height: 100px;
            margin-top: -100px;
            & > div {
                .page-title-area--inner {
                    position: relative;
                    background-color: white;
                    padding: 1.5rem 1.5rem 0;
                    min-height: 100px;
                }
            }
        }
    }
}

/* section with light tint background */

.section--bg-light {
    background-color: var(--light);
}

// full-width--breakout (make content that has to be inside a column but needs to be full width)

.full-width-breakout {
    display: block;
    width: 100%;
    &--inner {
        display: block;
        position: relative;
        width: var(--vpWidth);
        margin: 0 0 0 calc((var(--vpWidth) - 100%) * -.5) !important;
    }
}

/* 50/50 layout with optional carousel */

.split-img-content-layout {
    display: flex;
    flex-direction: column;

    .split-img-content-layout--img {
        background-color: var(--dark);
        width: 100%;
    }

    .split-img-content-layout--content {
        width: 100%;
        border-top: 1px solid $light;
        border-bottom: 1px solid $light;
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: stretch;

        .split-img-content-layout--img {
            position: relative;
            width: 50%;
            min-height: 100%;
            overflow: hidden;

            &:before { /* used to set the minimum section height to 4:3 image height */
                display: block;
                content: '';
                width: 100%;
                height: 0;
                padding-bottom: 75%;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: calc(100% + 1px);
                object-fit: cover;
                font-family: 'object-fit: cover';
            }

            .carousel {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                .carousel-inner, .carousel-item {
                    height: 100%;
                    overflow: hidden;
                }
            }
        }

        .split-img-content-layout--content {
            display: flex;
            width: 50%;
            min-height: 100%;
            align-items: center;

            .split-img-content-layout--content--inner {
                width: 100%;

                .container { // set max-widths to align with other page elements
                    margin-left: #{$split-img-content--content--margin--lg};
                    margin-right: auto;
                    max-width: calc((#{map-get($container-max-widths, lg)} / 2) - #{$split-img-content--content--margin--lg});
                }
            }
        }

        &.split-img-content-layout--img-right {
            flex-direction: row-reverse;

            .split-img-content-layout--content {
                .container { // set max-widths to align with other page elements
                    margin-left: auto;
                    margin-right: #{$split-img-content--content--margin--lg};
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .split-img-content-layout--content {
            .split-img-content-layout--content--inner {
                .container {
                    margin-left: #{$split-img-content--content--margin--xl};
                    max-width: calc((#{map-get($container-max-widths, xl)} / 2) - #{$split-img-content--content--margin--xl});
                }
            }
        }

        &.split-img-content-layout--img-right {
            .split-img-content-layout--content {
                .container {
                    margin-left: auto;
                    margin-right: #{$split-img-content--content--margin--xl};
                }
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        .split-img-content-layout--content {
            .split-img-content-layout--content--inner {
                .container {
                    margin-left: #{$split-img-content--content--margin--xxl};
                    max-width: calc((#{map-get($container-max-widths, xxl)} / 2) - #{$split-img-content--content--margin--xxl});
                }
            }
        }

        &.split-img-content-layout--img-right {
            .split-img-content-layout--content {
                .container {
                    margin-left: auto;
                    margin-right: #{$split-img-content--content--margin--xxl};
                }
            }
        }
    }
}

// section with image background
.section-with-bg-img {
    position: relative;
    .section-with-bg-img--bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        overflow: hidden;
        img {
            display: block;
            width: 100%;
            height: calc(100% + 1px);
            object-fit: cover;
        }
    }
    .section-with-bg-img--content {
        position: relative;
        z-index: 2;
        width: 100%;
    }
    &.full-height {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: var(--vpHeight);
    }
}

// widescreen image panel overlap
.widecreen-image-panel-overlap {
    --img-width: 640px;
    --content-width: 376px;
    &--img {
        img {
            aspect-ratio: 16/9;
        }
    }
    @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        min-height: calc(var(--img-width) * .5625);
        padding: 48px 0;
        &--img {
            display: block;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: var(--img-width);
            height: 100%;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &--content {
            position: relative;
            z-index: 2;
            width: var(--content-width);
        }
        &.widecreen-image-panel-overlap--img-right {
            justify-content: flex-start;
            .widecreen-image-panel-overlap--img {
                left: auto;
                right: 0;
            }
        }
    }
    @include media-breakpoint-up(xl) {
        --img-width: 736px;
        --content-width: 451px;
    }
    @include media-breakpoint-up(xxl) {
        --img-width: 856px;
        --content-width: 526px;
    }
}

// split-image-bg-sections 

.split-image-bg-sections {
    --gap: 30px;
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    .split-image-bg-section {
        display: flex;
        position: relative;
        width: 100%;
        min-height: var(--vpWidth);
        justify-content: center;
        align-items: center;
        padding: var(--edge-padding);
        &--img {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            aspect-ratio: none;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &--content {
            position: relative;
            z-index: 2;
            width: min(500px, 100%);
        }
    }
    @include media-breakpoint-up(sm) {
        .split-image-bg-section {
            min-height: calc(var(--vpWidth) * .75);
        }
    }
    @include media-breakpoint-up(md) {
        .split-image-bg-section {
            min-height: calc(var(--vpWidth) * .5625);
        }
    }
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        min-height: calc((var(--vpWidth) * .5625) / 2);
        .split-image-bg-section {
            width: calc(50% - 15px);
            min-height: 100%;
            justify-content: flex-start;
            &--content {
                width: min(500px, 75%);
            }
            &:nth-child(2) {
                justify-content: flex-end;
            }
        }
    }
}

// snow overlay
@keyframes snow-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
#snow-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: var(--vpWidth);
    height: var(--vpHeight);
    pointer-events: none;
    z-index: 99999;
    transform: translate3d(0,0,0);
    animation: snow-fade-in 1s linear;
    animation-fill-mode: forwards;
    transition: opacity 1s linear;
}
