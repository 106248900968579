.image-gallery {
    ul {
        margin-left: -15px;
        margin-right: -15px;
        font-size: 0;
        li {
            margin: $image-gallery--thumb--margin;
            font-size: 1rem;
            figure {
                margin: 0;
                a {
                    display: block;
                    img {
                        height: $image-gallery--small-thumb-height;
                        width: auto;
                        @include media-breakpoint-up(sm) {
                            height: $image-gallery--medium-thumb-height;
                        }
                        @include media-breakpoint-up(lg) {
                            height: $image-gallery--large-thumb-height;
                        }
                    }
                    .img-scale-wrapper {
                        background-color: var(--dark);
                    }
                }
            }
        }
    }

    /* horizontal scrolling version */

    &--horizontal-scrolling {
        display: block;
        width: 100%;
        margin: 15px 0;
        padding: 15px 0;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        border-bottom: 5px solid transparent;
        &::-webkit-scrollbar {
            margin: 0;
            background-color: transparent;
            height: 14px;
        }
        &::-webkit-scrollbar-track {
            height: 14px;
            margin: 0 var(--bs-gutter-x);
            background-color: var(--rule--color);
        }
        &::-webkit-scrollbar-thumb {
            box-sizing: border-box;
            border: 3px solid transparent;
            background-clip: padding-box;
            height: 14px;
            background-color: var(--link--color);
            cursor: pointer;
            &:hover {
                background-color: var(--link--color--hover);
            }
        }
        scrollbar-face-color: var(--link--color);
        scrollbar-arrow-color: var(--link--color);
        scrollbar-track-color: var(--rule--color);
        scrollbar-shadow-color: var(--link--color);
        scrollbar-highlight-color: var(--link--color);
        scrollbar-3dlight-color: var(--link--color);
        scrollbar-darkshadow-Color: var(--link--color);
        ul {
            display: block;
            position: relative;
            width: intrinsic;           /* Safari/WebKit uses a non-standard name */
            width: -moz-max-content;    /* Firefox/Gecko */
            width: -webkit-max-content; /* Chrome */
            width: -ms-max-content; /* IE */
            width: max-content;
            margin: 0 auto;
            padding: 0 var(--bs-gutter-x);
            white-space: nowrap;
            overflow: visible;
            text-align: center;
            li {
                margin: 0 15px 0 0;
                padding: 0;
                vertical-align: top;
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    /* Carousel version */

    &--carousel {
        .carousel-indicators {
            margin: 0;
            bottom: 10px;
        }
        .carousel-inner {
            z-index: 1;
        }
        figure {
            margin: 0;
        }
        .carousel-control-prev, .carousel-control-next {
            z-index: 10;
            width: 40px;
            font-size: 3rem;
        }
    }
}
    
// photoswipe style mods

.pswp {
    .pswp__caption__center {
        text-align: center;
        font-size: 1rem;
        line-height: 1.2rem;
    }
}
