a, .btn, button {
    svg[data-icon="angle-right"], svg[data-icon="angle-left"], svg[data-icon="arrow-right"], svg[data-icon="arrow-left"] {
        will-change: transform;
        transition: transform 200ms ease-in-out;
    }
    svg[data-icon="angle-right"], svg[data-icon="arrow-right"] {
        margin-left: 0.5em;
    }
    svg[data-icon="angle-left"], svg[data-icon="arrow-left"] {
        margin-right: 0.5em;
    }
    &:not(:disabled):not(.disabled) {
        &:hover, &:focus {
            svg[data-icon="angle-right"], svg[data-icon="arrow-right"] {
                transform: translate3d(0.3em,0,0);
            }
            svg[data-icon="angle-left"], svg[data-icon="arrow-left"] {
                transform: translate3d(-0.3em,0,0);
            }
        }
    }
    &:disabled, &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
}

.btn:not(.btn-link) {
    text-transform: var(--btn--text-transform);
    letter-spacing: var(--btn--letter-spacing);
    font-size: var(--btn--font-size);
}

.btn.form-select {
    padding-right: 2.25rem !important;
}

.btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
    color: white;
    &:not(:disabled):not(.disabled) {
        &:hover, &:focus {
            background-color: var(--primary--hover);
            border-color: var(--primary--hover);
            color: white;
        }
    }
}
.btn-outline-primary {
    border-color: var(--primary);
    color: var(--primary);
    &:not(:disabled):not(.disabled) {
        &:hover, &:focus {
            background-color: var(--primary);
            border-color: var(--primary--hover);
            color: white;
        }
    }

}

.btn-secondary {
    background-color: var(--secondary);
    border-color: var(--secondary);
    color: white;
    &:not(:disabled):not(.disabled) {
        &:hover, &:focus {
            background-color: var(--secondary--hover);
            border-color: var(--secondary--hover);
            color: white;
        }
    }
}
.btn-outline-secondary {
    border-color: var(--secondary);
    color: var(--secondary);
    &:not(:disabled):not(.disabled) {
        &:hover, &:focus {
            background-color: var(--secondary--hover);
            border-color: var(--secondary--hover);
            color: white;
        }
    }

}

select.btn-outline-primary, select.btn-outline-secondary {
    &:hover, &:focus {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    }
}
