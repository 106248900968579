.timetable {
    .timetable--date {
        background: white;
        margin-bottom: 1px;

        input[readonly] {
            background-color: white;
            width: 248px;
            padding-right: 30px;
            background-image: var(--timetable--date-input--background-image);
            background-position: calc(100% - 10px) center;
            background-repeat: no-repeat;
            background-size: 1rem;
        }
    }

    .timetable--filters {
        background-color: white;
        margin-bottom: 1px;

        button[data-bs-toggle="collapse"] {
            display: flex;
            gap: 5px;
            width: 100%;
            align-items: center;

            .indicator {
                margin-left: auto;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .timetable--filters--column select {
                max-width: 100%;
            }

            @include media-breakpoint-up(lg) {
                flex-direction: row;
                flex-wrap: wrap;
                align-items: flex-end;
                gap: 20px 30px;

                .timetable--filters--column:last-child {
                    margin-left: auto;
                }
            }

            @include media-breakpoint-up(lg) {
                gap: 20px 45px;
            }

            @include media-breakpoint-up(xxl) {
                gap: 20px 60px;
            }
        }
    }

    .loader {
        display: block;
        width: 100%;
        background-color: white;
        padding: 15px;
        text-align: center;
    }

    ol {
        display: block;
        position: relative;
        width: 100%;
        padding: 0;
        margin: 0;

        li {
            display: block;
            background-color: white;
            padding: 15px;
            margin: 0 0 1px;

            &.alt-bg {
                background-color: var(--timetable--alternate-darker-shade);
            }

            &.timetable--faux-table-head {
                font-family: var(--strong--font-family);
                font-weight: var(--strong--font-weight);
                font-size: max(14px, 0.875rem);
                text-transform: uppercase;
                color: var(--timetable--column-headings--color);
            }

            &.no-results {
                display: none;
            }

            .timetable--item--inner {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;

                .timetable--item--day {
                    abbr {
                        text-decoration: none;
                        cursor: default;
                    }

                    width: 4rem;
                }

                .timetable--item--timeslot {
                    width: calc(100% - 4rem);
                }

                .timetable--item--activity {
                    font-family: var(--strong--font-family);
                    font-weight: var(--strong--font-weight);
                    width: 100%;
                    // additional info icon
                    --link--color: var(--timetable--info--color);
                }

                .timetable--item--details {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    gap: 15px;
                    width: 100%;
                }

                .timetable--item--info {
                    margin-top: 0.85rem;
                }

                .timetable--item--cta {
                    white-space: nowrap;
                }

                .timetable--item--additional {
                    width: 100%;

                    &--inner {
                        position: relative;
                        margin: 20px 0 10px;
                        padding: 10px 30px 1px 10px;
                        border: 1px solid rgba(black, 0.06);
                        background: rgba(black, 0.03);

                        p {
                            margin-bottom: 10px;
                        }

                        & > button {
                            position: absolute;
                            top: 10px;
                            right: 10px;
                        }
                    }
                }

                @include media-breakpoint-down(lg) {
                    .timetable--item--day, .timetable--item--timeslot, .timetable--item--location, .timetable--item--facility, .timetable--item--tutor {
                        font-size: max(14px, 0.875rem);
                    }

                    .timetable--item--location, .timetable--item--facility, .timetable--item--tutor {
                        display: inline;
                    }

                    .divider {
                        display: inline-block;
                        width: 1px;
                        height: 1rem;
                        margin: 0 0.5em 0 0.2em;
                        background-color: var(--rule--color);
                        vertical-align: middle;
                    }
                }

                @include media-breakpoint-up(lg) {
                    & > div {
                        padding-right: 10px;
                    }

                    .timetable--item--day {
                        width: 5%;
                        margin: 0;
                    }

                    .timetable--item--timeslot {
                        width: 12.5%;
                    }

                    .timetable--item--activity {
                        width: 20%;
                    }

                    .timetable--item--details, .timetable--item--info {
                        display: contents;

                        div {
                            padding-right: 15px;
                        }
                    }

                    .timetable--item--location {
                        width: 22.5%;
                    }

                    .timetable--item--facility {
                        width: 15%;
                    }

                    .timetable--item--tutor {
                        width: 12.5%;
                    }

                    .timetable--item--cta {
                        width: 12.5%;
                        padding-right: 0;

                        .btn {
                            margin: -5px 0;
                        }
                    }

                    .timetable--item--additional {
                        padding-right: 0;
                    }
                }
            }

            &.warning {
                .timetable--item--inner {
                    .timetable--item--additional {
                        &--inner {
                            border: 1px solid rgba(red, 0.1);
                            background: rgba(red, 0.05);
                        }
                    }

                    .timetable--item--activity {
                        // additional info icon
                        --link--color: var(--timetable--warning--color);
                    }
                }
            }
        }
    }

    .timetable--expired {
        background-color: #D3D3D3;
    }
}
