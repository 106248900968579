.panel {
    padding: $container-padding-x; // default-padding
    background-color: var(--panel--bg-color);
    color: var(--panel--color);
    & > *:last-child, & > *[data-hm-section]:last-child > *:last-child {
        margin-bottom: 0;
    }

    &.panel--over-image {
        --color--emphasis: var(--panel--over-image--color);
        background-color: var(--panel--over-image--bg-color);
        color: var(--panel--over-image--color);
        backdrop-filter: blur(20px);
    }

    &.panel--white {
        --panel--bg-color: white;
        background-color: white;
    }

    &.panel--dark {
        --color:                    var( --panel--dark--color);
        --primary:                  var( --panel--dark--primary);
        --primary--hover:           var( --panel--dark--primary--hover);
        --secondary:                var( --panel--dark--secondary);
        --secondary--hover:         var( --panel--dark--secondary--hover);
    
        --decor--color:             var( --panel--dark--decor--color);
        --rule--color:              var( --panel--dark--rule--color);
        --color--emphasis:          var( --panel--dark--color--emphasis);
        --color--subtle:            var( --panel--dark--color--subtle);
    
        --link--color:              var( --panel--dark--link--color);
        --link--text-decoration:    var( --panel--dark--link--text-decoration);
        --link--color--hover:       var( --panel--dark--link--color--hover);
    
        background-color:           var(--panel--dark--background-color);
        color: var(--color);
        a:not(.btn, .dropdown-item, .page-link, .visual-listing--item), .btn.btn-link {
            text-decoration-color: rgba(255,255,255,0.4);
            &:hover, &:focus {
                text-decoration-color: var(--link--color--hover);
            }
        }
    }
    
    &.wide-image-text-panel {
        display: block;
        .wide-image-text-panel--image {
            display: block;
            margin-bottom: 15px;
        }
        .wide-image-text-panel--content {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            min-height: 100%;
            justify-content: center;
            align-items: flex-start;
            .wide-image-text-panel--content--inner {
                & > *:last-child {
                    margin-bottom: 0;
                }
                ul.taglist {
                    margin: 0 0 10px;
                    justify-content: flex-end;
                    @include media-breakpoint-up(md) {
                        float: right;
                        margin: 0 0 15px 15px;
                        max-width: 50%;
                    }
                    @include media-breakpoint-up(lg) {
                        max-width: 33%;
                    }
                    @include media-breakpoint-up(xl) {
                        max-width: 25%;
                    }
                }
            }
        }
        @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            .wide-image-text-panel--image {
                position: relative;
                flex: 0 0 auto;
                min-height: 100%;
                width: 50%;
                max-width: 300px;
                margin: 0;
                &:before { // minimum ratio 16:9
                    display: block;
                    content: '';
                    width: 100%;
                    height: 0;
                    padding-bottom: 56.25%;
                }
                .img-scale-wrapper, img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .wide-image-text-panel--content {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                min-height: 100%;
                justify-content: center;
                align-items: flex-start;
                .wide-image-text-panel--content--inner {
                    width: 100%;
                }

            }
            // image right
            &.wide-image-text-panel--image-right {
                .wide-image-text-panel--image {
                    order: 2;
                }
                .wide-image-text-panel--content {
                    order: 1;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            .wide-image-text-panel--image {
                max-width: 400px;
            }
        }
    }
}

.map-panel {
    width: 100%;

    .map-mask {
        position: relative;
        width: 100%;
        height: 0;
        padding: 0 0 100%;
        overflow: hidden;
        z-index: 0;

        @include media-breakpoint-up(md) {
            padding-bottom: 56.25%;
        }

        iframe, picture, img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-width: none;
            z-index: 0;
            border: 0;
        }
    }
}
