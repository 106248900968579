// ************************************************
// *****  default DCLT fonts  *****
// ************************************************

    // body font
    @font-face {
        font-family: 'Poppins Regular';
        font-style: normal;
        font-weight: 400;
        src: url('../../fonts/poppins/poppins-v19-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('../../fonts/poppins/poppins-v19-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../../fonts/poppins/poppins-v19-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
            url('../../fonts/poppins/poppins-v19-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
            url('../../fonts/poppins/poppins-v19-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../../fonts/poppins/poppins-v19-latin-ext_latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
    }
    // strong + headings font
    @font-face {
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 700;
        src: url('../../fonts/poppins/poppins-v19-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('../../fonts/poppins/poppins-v19-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../../fonts/poppins/poppins-v19-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
            url('../../fonts/poppins/poppins-v19-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
            url('../../fonts/poppins/poppins-v19-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../../fonts/poppins/poppins-v19-latin-ext_latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
    }
    

// ****************************
// *****  Hatfield fonts  *****
// ****************************


    // strong + headings font
    @font-face {
        font-family: 'Phosphate Inline';
        font-style: normal;
        font-weight: 700;
        src: url('../../fonts/phosphate-inline/phosphateinline-webfont.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('../../fonts/phosphate-inline/phosphateinline-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../../fonts/phosphate-inline/phosphateinline-webfont.woff2') format('woff2'), /* Super Modern Browsers */
            url('../../fonts/phosphate-inline/phosphateinline-webfont.woff') format('woff'), /* Modern Browsers */
            url('../../fonts/phosphate-inline/phosphateinline-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../../fonts/phosphate-inline/phosphateinline-webfont.svg#Phosphate') format('svg'); /* Legacy iOS */
    }
