h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .display-1, .display-2, .display-3, .display-4 {
    margin-bottom: max(1rem, 0.75em);
    color: var(--color--emphasis);
    .hero .panel & {
        margin-bottom: 1rem;
    }
    a {
        color: var(--color--emphasis); 
        &:hover, &:focus {
            color: var(--link--color--hover);
        }
    }
}
h1, h2, .h1, .h2, .display-1, .display-2, .display-3, .display-4 {
    font-family: var(--major-headings--font-family);
    font-weight: var(--major-headings--font-weight);
    text-transform: var(--major-headings--text-transform);
    @include non-retina-font-smoothing-antialiased;
}
h3, h4, h5, h6, .h3, .h4, .h5, .h6 {
    text-transform: var(--headings--text-transform);
    @include non-retina-font-smoothing-antialiased;
}

.muted {
    color: var(--color--subtle);
}

.lead, strong, b {
    font-family: var(--strong--font-family);
    font-weight: var(--strong--font-weight);
}

.instagram-logo {
    --height: 1.1em;
    display: inline-block;
    width: calc(var(--height) * 3.525);
    height: var(--height);
    background: url('../../img/logos/instagram-logo.svg') no-repeat;
    background-size: 100%;
    vertical-align: middle;
    transform: translateY(2%);
}

.tripadvisor-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
}
