.testimonial {
    padding: 0;
    margin: 0;
    color: var(--testimonial--color);
    &--star-rating {
        color: var(--testimonial--stars--color);
    }
    &--quote {
        color: var(--testimonial--quote--color);
    }
}

.testimonials-section {
    background-color: var(--testimonials-section--bg-color);
    #testimonials-swiper {
        position: relative;
        padding: 0 40px;
        will-change: transform;
        user-select: none;
        .swiper-wrapper {
            align-items: center;
            z-index: 1;
        }
        .swiper--prev, .swiper--next {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.6rem;
            padding: 0;
            color: var(--link--color);
            &:not(:disabled):not(.disabled) {
                &:hover, &:focus {
                    color: var(--link--color--hover);
                }
            }
        }
        .swiper--prev {
            left: 0;
        }
        .swiper--next {
            right: 0;
        }
    }
}
