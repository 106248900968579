﻿// ********************
// **  Site colours  **
// ********************

$dclt--dark-blue:           #181736;
$dclt--darkest-blue:        #010028;
$dclt--red:                 #c91f28;
$dclt--light-grey:          #e9eaec;
$dclt--dark-grey:           #293341;

$dclt--primary:             $dclt--red;
$dclt--primary-hover:       darken($dclt--primary, 8%);
$dclt--secondary:           $dclt--dark-blue;
$dclt--secondary-hover:     $dclt--primary-hover;

// Hatfield

$hatfield--peach:               #e8553f;
$hatfield--orange:              #f68c2e;
$hatfield--yellow:              #f8b841;
$hatfield--light-yellow:        #fbd58f; // our addition
$hatfield--teal:                #52bfa2;
$hatfield--light-green:         #7d9d62;
$hatfield--green:               #3b6839;
$hatfield--dark-grey:           #25201f;

$hatfield--primary:             $hatfield--peach;
$hatfield--primary-hover:       darken($hatfield--peach, 8%);
$hatfield--secondary:           $hatfield--teal;
$hatfield--secondary-hover:     $hatfield--primary-hover;

// Crookhill

$crookhill--light-green:         rgb(142,164,0);
$crookhill--dark-green:          rgb(11,77,0);
$crookhill--sand:                rgb(243, 236, 224);

$crookhill--primary:             $crookhill--light-green;
$crookhill--primary-hover:       darken($crookhill--light-green, 8%);
$crookhill--secondary:           $crookhill--dark-green;
$crookhill--secondary-hover:     $crookhill--primary-hover;

// Choose Fitness

$choose-fitness--primary:           rgb(237, 118, 38);
$choose-fitness--primary-hover:     darken($choose-fitness--primary, 10%);

$choose-swim--primary:              rgb(0, 174, 239);
$choose-swim--primary-hover:        darken($choose-swim--primary, 10%);

$choose-skate--primary:             #A5A3A3;
$choose-skate--primary-hover:       darken($choose-skate--primary, 10%);

$choose-gymnastics--primary:        #6f3b96;
$choose-gymnastics--primary-hover:  darken($choose-gymnastics--primary, 10%);

$choose-sport--primary:             #ec2426;
$choose-sport--primary-hover:       darken($choose-sport--primary, 10%);

$choose-cycle--primary:             #fff200;
$choose-cycle--primary-hover:       darken($choose-cycle--primary, 10%);

// Ice Caps colours

$ice-caps--primary:                 #0072bb;
$ice-caps--primary-hover:           darken($ice-caps--primary, 10%);
$ice-caps--black:                   #231f1e;

// Misc colours

$color--light-grey: 	rgb(233,234,236);
$color--dark-grey: 	    rgb(91,103,112);
$color--darkest-grey: 	rgb(45,41,38);

$color--summer-yellow:  #ffee23;
$color--summer-blue:    #23c3ff;
$color--summer-orange:  #ff9423;
$color--summer-lilac:   #dca7f5;

$color--halloween-yellow:  #ECB631;
$color--halloween-orange:    #D4682F;
$color--halloween-dark-blue:  #1E285F;
$color--halloween-light-blue:   #69B4E6;
$color--halloween-black:   #1D1D1B;

$color--christmas-brown:      #977545;
$color--christmas-cream:      #E9E3C9;
$color--christmas-green:      #4A8764;
$color--christmas-red:        #BD3E2F;
$color--christmas-dark-red:   #832831;

// set fundamental bootstrap colour variables

$primary:                       $dclt--primary;
$secondary:                     $dclt--secondary;
$light:                         $dclt--light-grey;
$dark:                          $dclt--dark-grey;

$body-bg:                       white;
$body-color:                    $dark;

$link-color:                    $dclt--primary;
$link-decoration:               none;
$link-hover-color:              $dclt--primary-hover;
$link-hover-decoration:         underline;

// Hero area
$hero--fullscreen--height-reduction--mobile:             30px;
$hero--fullscreen--height-reduction--desktop:            30px;
$hero--img--min-heights: (
    xs: 75vw,
    sm: 75vw,
    md: 300px,
    lg: 350px,
    xl: 400px,
    xxl: 500px
);
$hero--img--heights: (
    xs: 75vw,
    sm: 75vw,
    md: calc(100vh - 200px),
    lg: calc(100vh - 200px),
    xl: calc(100vh - 200px),
    xxl: calc(100vh - 200px)
);
$hero--img--max-heights: (
    xs: 75vw,
    sm: 75vw,
    md: 450px,
    lg: 500px,
    xl: 550px,
    xxl: 600px
);

// 50/50 split layouts

$split-img-content--content--margin--lg:    30px;
$split-img-content--content--margin--xl:    30px;
$split-img-content--content--margin--xxl:   30px;

// Image galleries

$image-gallery--small-thumb-height: 120px;
$image-gallery--medium-thumb-height: 180px;
$image-gallery--large-thumb-height: 240px;
$image-gallery--thumb--margin: 5px;




// ******************************************************************
// **                                                              **
// **   BOOTSTRAP VARIABLES - (overriding bootstrap defaults)      **
// **                                                              **
// ******************************************************************

$component-active-bg:           $primary;
$component-active-color:        white !important;

// Fonts

$font-family-base:             var(--body--font-family);
$font-strong:                  var(--strong--font-family);

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-weight-base:            var(--body--font-weight);
$line-height-base:            1.5;

$lead-font-size:              $font-size-base * 1.05;
$lead-font-weight:            400;

$paragraph-margin-bottom:     1.25rem;

$headings-font-family:        var(--headings--font-family);
$headings-font-weight:        var(--headings--font-weight);
$headings-line-height:        1.2;

$h1-font-size:                $font-size-base * 2;
$h2-font-size:                $font-size-base * 1.7;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.3;
$h5-font-size:                $font-size-base * 1.1;
$h6-font-size:                $font-size-base;
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
);

$display-font-sizes: (
  1: 3.4rem,
  2: 3rem,
  3: 2.8rem,
  4: 2.6rem,
  5: 2.4rem,
  6: 2.4rem
);

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 3,
        ),
        // added
        6: (
            $spacer * 4,
        ),
        // added
        7:
            (
                $spacer * 5,
            ),
        // added
        8:
            (
                $spacer * 6,
            )
            // added,
    ),
    $spacers
);

// Forms + Buttons

$input-btn-padding-y:         .65rem;
$input-btn-padding-x:         1.15rem;
$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-padding-y-lg:      0.95rem;
$input-btn-padding-x-lg:      1.5rem;

$border-radius:                   0;
$border-radius-lg:                0;
$border-radius-sm:                0;

$btn-border-radius:           0;
$btn-border-radius-sm:        $btn-border-radius;
$btn-border-radius-lg:        $btn-border-radius;

$form-select-border-radius:       $btn-border-radius;
$form-select-border-radius-sm:    $btn-border-radius;
$form-select-border-radius-lg:    $btn-border-radius;

$form-check-input-border-radius:    0;
$form-check-input-checked-color:    white;

// Pagination

$pagination-border-width:           0;
$pagination-border-radius:          0;
