﻿.responsive-nav-bar {
    --side-btn--width: 30px;
    --side-btn--margin: 5px;
    position: relative;
    width: 100%;
    background-color: var(--bg-color);
    color: white;
    padding: 0 5px;
    will-change: transform;
    user-select: none;
    box-shadow: 0 1px 4px rgba(black, 0.2);

    &--button {
        display: none;
        position: absolute;
        top: 0;
        padding: 0;
        z-index: 2;
        height: 100%;
        width: var(--side-btn--width);
        justify-content: center;
        align-items: center;
        border-radius: 0;
        will-change: transform;
        outline: 0;
        &.previous {
            left: 0;
        }
        &.next {
            right: 0;
        }
    }
    .responsive-nav-bar--ul-mask {
        position: relative;
        overflow: hidden;
        padding: 0;
        @include media-breakpoint-up(lg) {
            margin: 0 auto;
            max-width: map-get($container-max-widths, lg);
        }
        @include media-breakpoint-up(xl) {
            margin: 0 auto;
            max-width: map-get($container-max-widths, xl);
        }
        @include media-breakpoint-up(xxl) {
            margin: 0 auto;
            max-width: map-get($container-max-widths, xxl);
        }
        ul {
            min-width: 100%;
            width: intrinsic;           /* Safari/WebKit uses a non-standard name */
            width: -moz-max-content;    /* Firefox/Gecko */
            width: -webkit-max-content; /* Chrome */
            width: -ms-max-content; /* IE */
            transition: transform 300ms ease-in-out;
            will-change: transform;
            padding: 5px 0;
            justify-content: space-around;
            gap: 10px;
            @include media-breakpoint-up(lg) {
                min-width: map-get($container-max-widths, lg);
            }
            @include media-breakpoint-up(xl) {
                min-width: map-get($container-max-widths, xl);
            }
            @include media-breakpoint-up(xxl) {
                min-width: map-get($container-max-widths, xxl);
            }
            .nav-item {
                flex: 0 0 auto;
            }
            .nav-link {
                position: relative;
                z-index: 1;
                background-color: var(--bg-color);
                color: var(--color);
                text-decoration: none;
                transition: background-color 500ms linear;
                outline: 0;
                font-family: var(--strong--font-family);
                font-weight: var(--strong--font-weight);
                font-size: 16px;
                text-transform: uppercase;
                &:not(.active) {
                    &:hover, &:focus {
                        transition-duration: 200ms;
                        background-color: var(--primary--hover);
                        color: white;
                        text-decoration: none;
                    }
                }
                &.active {
                    --link-color: white;
                    --link-color-hover: white;
                    background-color: var(--primary);
                    color: white !important;
                    transition-duration: 200ms;
                    cursor: default;
                    &:hover, &:focus {
                        background-color: var(--primary);
                        color: white !important;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    &.scroll {
        padding-left: calc(var(--side-btn--width) + var(--side-btn--margin));
        padding-right: calc(var(--side-btn--width) + var(--side-btn--margin));
        .responsive-nav-bar--button {
            display: flex;
            i, svg {
                margin: 0 !important;
            }
        }
    }
    // sticky positioning
    @supports(position: sticky) {
        &.responsive-nav-bar--sticky {
            position: sticky;
            z-index: 8;
            top: calc(var(--top-utility-bar--height) + var(--header--height));
            transition: top 300ms ease-in-out;
            will-change: transform;
            .header-hidden & {
                top: 0;
            }
        }
    }
}
