#main-footer {
    --link--color: var(--footer--link--color);
    --link--color--hover: var(--footer--link--color--hover);
    background-color: var(--footer--bg-color);
    color: var(--footer--color);

    footer {

        #main-footer--social-links {
            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 20px;
                li {
                    margin: 0;
                    a {
                        font-size: 1.4rem;
                        &:hover, &:focus {
                            text-decoration: none;
                        }
                    }
                }
                @include media-breakpoint-up(lg) {
                    justify-content: flex-end;
                }
            }
        }
    }

    #main-footer--primary-links {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        text-align: center;
        .main-footer--primary-links--group {
            margin: 0;
            h3 {
                margin: 0;
                button {
                    color: var(--footer--headings--color);
                    padding: 0;
                    svg {
                        margin-left: 0.5em;
                        will-change: transform;
                        transition: transform 250ms ease-in-out;
                    }
                    &:hover, &:focus {
                        color: var(--footer--headings--color);
                    }
                    &[aria-expanded="true"] {
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
            ul {
                margin: 0;
                padding: 10px 0 0;
                display: flex;
                width: 100%;
                flex-direction: column;
                gap: 3px;
                align-items: center;
                li {
                    margin: 0;
                    padding: 0;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            text-align: left;
            .main-footer--primary-links--group {
                flex: 1 1 auto;
                min-width: 200px;
                .collapsing { // kill animation length
                    transition: height .01s linear;
                }
                h3 button {
                    &:disabled {
                        opacity: 1;
                    }
                    svg {
                        display: none;
                    }
                }
                ul {
                    align-items: flex-start;
                }
            }
        }
    }

    .footer-divider-rule {
        width: 100%;
        height: 1px;
        border: 0;
        background-color: var(--footer--divider--bg-color);
    }

    #main-footer--legal-copy {
        font-size: max(14px, 0.875rem);
    }

}
