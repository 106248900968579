$full-menu--desktop--side-image-min-width--lg: 200px;
$full-menu--desktop--side-image-min-width--xl: 250px;
$full-menu--desktop--side-image-min-width--xxl: 300px;

#full-menu {

    --full-menu--bg-color: white;
    --full-menu--color: #{$dark};

    background-color: var(--full-menu--bg-color);
    color: var(--full-menu--color);

    position: absolute;
    top: calc(var(--top-utility-bar--height) + var(--header--height));
    left: 0;
    z-index: 2;
    width: 100%;
    height: calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height)));
    display: none;
    opacity: 0;
    pointer-events: none;
    &[aria-hidden="false"] {
        display: block;
        opacity: 0;
        transition: opacity 200ms linear;
        will-change: opacity;
    }
    .menu-active & {
        opacity: 1;
        pointer-events: all;
    }

    #full-menu--content {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        #full-menu--content-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            min-height: 100%;

            // mobile styles
            @include media-breakpoint-down(lg) {
                padding: 40px 0;
                --x-padding: max(#{$grid-gutter-width}, calc((100% - var(--full-menu--mobile--max-width))/2));
                #full-menu--feature-links {
                    width: 100%;
                    padding: 0 var(--x-padding) 30px;
                    ul {
                        display: flex;
                        gap: 20px;
                        flex-wrap: wrap;
                        li .btn {
                            font-size: 0.875rem;
                        }
                    }
                }
                #full-menu--mobile-search {
                    flex: 0 0 auto;
                    padding: 0 var(--x-padding) 15px;
                    form {
                        display: flex;
                        width: 100%;
                        gap: 10px;
                        input[type="search"] {
                            flex: 1 1 auto;
                        }
                        button[type="submit"] {
                            flex: 0 1 auto;
                        }
                    }
                }
                nav {
                    display: block;
                    width: 100%;
                    .full-menu--sections {
                        width: 100%;
                        flex: 1 0 auto;
                        .menu-section {
                            position: relative;
                            padding: 15px var(--x-padding);
                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                z-index: -1;
                                background-color: var(--full-menu--desktop-lower--bg-color);
                                opacity: 0;
                                transition: opacity 200ms linear;
                            }
                            &.expanded:before {
                                opacity: 1;
                            }
                            & > a, & > button {
                                all: unset;
                                box-sizing: border-box;
                                display: block;
                                position: relative;
                                width: 100%;
                                font-family: var(--headings--font-family);
                                font-weight: var(--headings--font-weight);
                                text-transform: var(--headings--text-transform);
                                font-size: var(--full-menu--mobile--section-btn--font-size);
                                cursor: pointer;
                                @include non-retina-font-smoothing-antialiased;
                                &:hover, &:focus {
                                    .indicator {
                                        opacity: 1;
                                    }
                                }
                            }
                            & > button {
                                padding-right: 1.6rem;
                                .indicator {
                                    display: block;
                                    position: absolute;
                                    top: var(--full-menu--mobile--section-btn--icon-top);
                                    right: 0;
                                    font-size: 1.6rem;
                                    opacity: 0.5;
                                    transform-origin: 50% 50%;
                                    transition: transform 300ms ease-in-out;
                                    will-change: transform;
                                }
                                &[aria-expanded="true"] {
                                    .indicator {
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                            ul {
                                padding: 20px 0 5px;
                                margin: 0;
                                li {
                                    line-height: 1.3;
                                    margin-bottom: 0.75rem;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    a {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                    #full-menu--mobile--utility-links {
                        margin-top: 30px;
                        border-top: 1px solid var(--full-menu--desktop-lower--bg-color);
                        padding: 30px var(--x-padding) 0;
                        font-size: 0.875rem;
                        ul {
                            display: flex;
                            justify-content: center;
                            gap: 20px;
                            flex-wrap: wrap;
                        }
                    }
                }
            }
            // desktop
            @include media-breakpoint-up(lg) {
                justify-content: space-between;
                margin: 0 auto;
                padding: 0;
                width: 100%;
                --max-width: 100%;
                --padding-right: #{$container-padding-x};
                #full-menu--feature-links {
                    margin: 0 auto;
                    padding: var(--full-menu--desktop-feature-links--padding-top) var(--padding-right) 0 var(--bs-gutter-x);
                    width: 100%;
                    max-width: var(--max-width);
                    ul {
                        display: flex;
                        gap: 20px;
                        flex-wrap: wrap;
                    }
                }
                nav {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 100%;
                    flex: 1 0 auto;
                    --column-width: 33.33333%; // 3 column layout
                    .full-menu--upper-group, .full-menu--lower-group {
                        display: block;
                        width: 100%;
                        .full-menu--side-padding {
                            margin: 0 auto;
                            padding: 0 var(--padding-right) 0 var(--bs-gutter-x);
                            width: 100%;
                            max-width: var(--max-width);
                        }
                        .full-menu--upper-group--title, .full-menu--lower-group--title {
                            width: 100%;
                            flex: 0 0 auto;
                        }
                    }
                    .full-menu--upper-group {
                        padding-top: var(--full-menu--desktop-upper--padding-top);
                        padding-bottom: var(--full-menu--desktop-upper--padding-top);
                        .full-menu--upper-group--title h2 {
                            font-size: var(--full-menu--desktop-upper-title--font-size);
                            margin-bottom: 1.5em;
                        }
                    }
                    .full-menu--lower-group {
                        padding-top: var(--full-menu--desktop-lower--padding-top);
                        padding-bottom: var(--full-menu--desktop-lower--padding-top);
                        background-color: var(--full-menu--desktop-lower--bg-color);
                        --column-width: 25%; // 4 column layout
                        font-size: 0.875rem;
                        .full-menu--lower-group--title h2 {
                            font-size: var(--full-menu--desktop-lower-title--font-size);
                            margin-bottom: 1.5em;
                        }
                    }
                    .full-menu--sections {
                        display: flex;
                        width: 100%;
                        flex: 0 0 auto;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        gap: 30px 0;
                        .menu-section {
                            flex: 0 0 auto;
                            width: var(--column-width);
                            padding-right: 30px;
                            margin: 0;
                            & > button {
                                all: unset;
                                pointer-events: none;
                                .indicator {
                                    display: none;
                                }
                            }
                            & > a, & > button {
                                font-family: var(--headings--font-family);
                                font-weight: var(--headings--font-weight);
                                text-transform: var(--headings--text-transform);
                                display: inline-block;
                                position: relative;
                                width: 100%;
                                margin-bottom: .75em;
                                @include non-retina-font-smoothing-antialiased;
                            }
                            & > a {
                                cursor: pointer;
                            }
                            ul {
                                padding: 0;
                                margin: 0;
                                li {
                                    line-height: 1.3;
                                    margin-bottom: 0.625em;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    a {
                                        cursor: pointer;
                                    }
                                }
                            }
                            
                        }
                    }
                }
            }
            @include media-breakpoint-up(xxl) {
                --max-width: #{map-get($container-max-widths, "xxl")}; // match container-fluid up to xxl
            }
        }
    }

    // full-menu--with-side-image?
    &.full-menu--with-side-image {
        @include media-breakpoint-up(lg) {

            #full-menu--content #full-menu--content-inner {
                --padding-right: calc(#{$full-menu--desktop--side-image-min-width--lg} + var(--bs-gutter-x));
            }

            #full-menu--image-area {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: $full-menu--desktop--side-image-min-width--lg;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    opacity: 0;
                    transition: opacity 1s linear;
                    &.lazyloaded {
                        opacity: 1;
                    }
                }

                // optional promo link
                .full-menu--image-area--promo {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 2;
                    width: 100%;
                    a {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        width: 100%;
                        padding: 15px;
                        background-color: var(--full-menu--promo--bg-color);
                        color: var(--full-menu--promo--color);
                        transition: background-color 250ms linear, color 250ms linear;
                        backdrop-filter: blur(20px);
                        .full-menu--image-area--promo--content {
                            flex: 1 1 auto;
                            h2 {
                                color: var(--full-menu--promo--color);
                                font-size: var(--full-menu--promo--h2--font-size);
                                margin-bottom: 0.2em;
                            }
                            p {
                                margin: 0;
                            }
                        }
                        .full-menu--image-area--promo--indicator {
                            flex: 0 1 auto;
                            font-size: 2rem;
                        }
                        &:hover, &:focus {
                            background-color: var(--full-menu--promo--bg-color--hover);
                            color: var(--full-menu--promo--color--hover);
                            .full-menu--image-area--promo--content {
                                h2 {
                                    color: var(--full-menu--promo--color--hover);
                                }
                            }
                        }
                    }
                }
            }
        }
        @include media-breakpoint-up(xl) {
            #full-menu--content #full-menu--content-inner {
                --padding-right: calc(#{$full-menu--desktop--side-image-min-width--xl} + var(--bs-gutter-x));
            }
            #full-menu--image-area {
                width: $full-menu--desktop--side-image-min-width--xl;
            }
        }
        @include media-breakpoint-up(xxl) {
            #full-menu--content #full-menu--content-inner {
                --padding-right: calc(#{$full-menu--desktop--side-image-min-width--xxl} + var(--bs-gutter-x));
            }
            #full-menu--image-area {
                width: calc(((100% - #{map-get($container-max-widths, "xxl")})/2) + #{$full-menu--desktop--side-image-min-width--xxl});
            }
        }
    }
}
